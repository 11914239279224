export const logo = require('@/assets/brands/logo-eng (2).png');
export const drawerLogos = {
    normal: require('@/assets/brands/ibn-alhaythem.png'),
    mini: require('@/assets/brands/ibn-alhaythem-mini.png')
};

export const colors = {
    // primary:  '#253c99',  
    // #0b5b6f //
    primary: '#0b5b6f',  
    // '#', // #253c99  #014a97  #  041943 
    primaryLight: '#c0dfff',
    //  '#ccddfd', // #b2c1ff #c0dfff # a0ecff
    // secondary: '#5371ad', #85ba00 // #f57f20 // #f57f20 #5371ad
    secondary: '#f57f20',
    lightBlue: '#0996b9', // #0996b9 # # 0996b9 85b901 103aa2
}